import { GET_USERS, GET_INFO_COUNT } from '../actions/types'

const initialState = {
    users:[],
    infoCount: [],
    loading: true
}

const testReducer = (state = initialState, action) => {

    switch(action.type){

        case GET_USERS:
            return {
                ...state,
                users: action.payload.users,
                loading: false
            }

        case GET_INFO_COUNT:
            return {
                ...state,
                infoCount: Object.entries(action.payload),
                loading: false
            }

        default: 
            return state
    }

}

export default testReducer
