import React, { useEffect } from 'react'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartLine, faUsers, faEdit } from '@fortawesome/free-solid-svg-icons'

const SideBarStyled = styled.div`
    width: 50px;
    height: calc(100vh - 70px);
    padding-top: 70px;
    background-color: #fff;
    z-index: 1; 
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: rgba(0,0,0,.1) 0 0 10px;
`
const Icon = styled(FontAwesomeIcon)`
    margin-bottom: 30px;
    cursor: pointer;
`
const HeaderFixed = styled.div`
    width: 100%;
    height: 50px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #2cbfc2;
    color: #fff;
    box-shadow: rgba(0,0,0,.2) 0px 0px 50px;
`

const SideBar = ({history}) => {

    useEffect(() => {
    }, [])

    return (
        <>
            <HeaderFixed>
                <h2>Thread Admin</h2>
            </HeaderFixed>
            <SideBarStyled>
                <Icon icon={faChartLine} color="#2cbfc2" size="lg" onClick={() => history.push('/dashboard')} />
                <Icon icon={faUsers} color="#2cbfc2" size="lg" onClick={() => history.push('/users')} />
                <Icon icon={faEdit} color="#2cbfc2" size="lg" />
            </SideBarStyled>
        </>
    )
}

export default withRouter(SideBar)