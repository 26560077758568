import { ADD_TODO, DELETE_TODO } from '../actions/types'

const initialState = {
    todos: [
        {
            id: 0,
            name: 'Ir a 1',
            complete: false
        },
        {
            id: 1,
            name: 'Ir a 2',
            complete: false
        }
    ]
}

const todoReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_TODO: 
        return {
            ...state,
            todos: [...state.todos, action.payload]
        }

        case DELETE_TODO:
            return {
                ...state,
                todos: state.todos.filter(todo => todo.id !== action.payload)
            }

        default: 
            return state;

    }
}

export default todoReducer