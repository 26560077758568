import { LOGIN, LOGOUT } from '../actions/types'

const initialState = {
    token: null,
    loading: true
}

const authReducer = (state = initialState, action) => {

    switch(action.type){

        case LOGIN:
            return {
                ...state,
                token: action.payload.token,
                loading: false
            }
        case LOGOUT:
            return {
                ...state,
                token: null,
                loading: false
            }
        default: 
            return state
    }

}

export default authReducer
