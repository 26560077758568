import { LOGIN, LOGIN_ERROR } from './types'
import axios from 'axios'

export const logIn = (data) => async dispatch => {
    try{

        return await axios.post(`${process.env.REACT_APP_API_ADMIN_URL}/login`, data).then( res => {
              dispatch( {
                type: LOGIN,
                payload: res.data
            })
          }).catch(err => {
              console.log(err, 'ERRR');
          })
    }
    catch(e){
        dispatch( {
            type: LOGIN_ERROR,
            payload: console.log(e),
        })
    }

}