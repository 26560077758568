import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from'react-redux'

import { logIn } from '../redux/actions/authAction'

import Background from '../static/images/shapes.jpg'; 


const LoginLayoutStyled = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-image: url(${Background});
    background-size: cover;
`
const Title = styled.h2`
    color: #2cbfc2;
    font-size: 40px;
    margin: 0;
`
const LoginFormStyled = styled.form`
    width: 400px;
    height: 400px;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, .5) 0 0 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
`
const InputStyled = styled.input`
    width: 90%;
    padding: 12px 5px;
    border-top-style: none;
    border-left-style: none;
    border-right-style: none;
    border-bottom-width: 1px;
    border-bottom-color: #e1e1e1;
    font-size: 16px;
    &:focus {
        outline: none;
    }
`
const LoginButtonStyled = styled.input`
    width: calc(90% + 10px);
    height: 50px;
    border-radius: 5px;
    background-color: #fff;
    border: 2px solid #2cbfc2;
    cursor: pointer;
    font-weight: bold;
    color: #339698;
    font-size: 16px;
    margin-bottom: 20px;
    &:hover {
        background-color: #2cbfc2;
        transition: .5s;
        color: #fff;
    }
` 

 const Login = ({history}) => {

    const [formValues, setFormValues] = useState({
        user: '',
        password: ''
    });

    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)

    const { token } = auth

    useEffect(() => {
        
    }, [token])


    const handleInput = (event) => {
        setFormValues({
          ...formValues,
          [event.target.name]: event.target.value,
        });
      };
    
      const handleSubmit = async (event) => {
        event.preventDefault();
        dispatch(logIn(formValues))        
      };

      if(token){
        history.push('/dashboard');
      }

    return (
        <LoginLayoutStyled>
            <LoginFormStyled 
                action=''
                onSubmit={handleSubmit}

            >
                <Title>Thread</Title>
                <InputStyled 
                    type="text" 
                    placeholder="Email" 
                    name='email' 
                    onChange={handleInput}
                />
                <InputStyled 
                    type="password" 
                    placeholder="Contraseña"
                    name='password' 
                    onChange={handleInput}
                />
                <LoginButtonStyled type="submit" value="Iniciar Sesión"/>
            </LoginFormStyled>
        </LoginLayoutStyled>
    )
}

export default withRouter(Login)