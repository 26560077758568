import React, { useEffect } from 'react'
import { useSelector, useDispatch } from'react-redux'

import { getUsers } from '../redux/actions/testAction'

import UsersList from '../modules/UsersList'

const Users = () => {
    const dispatch = useDispatch()

    const { token } = useSelector(state => state.auth)

    const test = useSelector(state => state.test)
    const {loading, users = []} = test

    useEffect(() => {
        dispatch(getUsers(token))
    }, [dispatch, token])


    return (
        <>
           {loading && (<div>Loading...</div>)} 
            {!loading && (<UsersList users={users}/>)}
        </>
    )
}

export default Users