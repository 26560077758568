export const ADD_TODO = 'ADD_TODO';
export const DELETE_TODO = 'DELETE_TODO';

export const GET_INFO_COUNT = 'GET_INFO_COUNT';
export const INFO_COUNT_ERROR = 'INFO_COUNT_ERROR';

export const GET_USERS = 'GET_USERS';
export const USERS_ERROR = 'USERS_ERROR';

export const LOGIN = 'LONGIN';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGOUT = 'LOGOUT';

export const PUBLISH_THREAD = 'PUBLISH_THREAD';
export const PUBLISH_THREAD_FAILED = 'PUBLISH_THREAD_FAILED';