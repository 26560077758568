import { GET_USERS, USERS_ERROR, GET_INFO_COUNT, INFO_COUNT_ERROR, LOGOUT } from './types'
import axios from 'axios'

export const getInfoCount = (token, history) => async dispatch => {
    try{
        const res = await axios.get(`${process.env.REACT_APP_API_ADMIN_URL}/general_information`, {
            headers: {
              'token': token
            }
          })
        
        dispatch( {
            type: GET_INFO_COUNT,
            payload: res.data
        })
    }
    catch(error){
        if (error && error.response && error.response.status === 401) {
            return dispatch( {
                type: LOGOUT,
                payload: null
            })
        }
        dispatch( {
            type: INFO_COUNT_ERROR,
            payload: console.log(error),
        })
    }

}

export const getUsers = (token) => async dispatch => {
    
    try{
        const res = await axios.get(`${process.env.REACT_APP_API_ADMIN_URL}/users_list?limit=100&page=0`, {
            headers: {
              'token': token
            }
          })
        
        dispatch( {
            type: GET_USERS,
            payload: res.data
        })
    }
    catch(e){
        dispatch( {
            type: USERS_ERROR,
            payload: console.log(e),
        })
    }

}

export const getUserThreads = (token, userId) => async dispatch => {
    
    try{
        return await axios.get(`${process.env.REACT_APP_API_ADMIN_URL}/user/${userId}/threads?limit=100&page=0`, {
            headers: {
              'token': token
            }
          })
    }
    catch(err){
        console.log({err});
    }

}

export const getThread = (token, threadId) => async dispatch => {
    
    try{
        return await axios.get(`${process.env.REACT_APP_API_ADMIN_URL}/thread/${threadId}`, {
            headers: {
              'token': token
            }
          })
    }
    catch(err){
        console.log({err});
    }

}
