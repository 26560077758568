import React, { useEffect } from 'react'
import { useSelector, useDispatch } from'react-redux'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom';

import { getInfoCount } from '../redux/actions/testAction'
import { InfoCountTitle } from '../helpers/constants'

const Layout = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    background-color: #f5f6f8;
`
const DataBox = styled.div`
    width: 250px;
    height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 10px;
    margin: 0 20px 30px;
    box-shadow: rgba(0,0,0,.1) 0px 0px 20px;
    cursor: pointer;
`

const Dashboard = ({history}) => {

    const dispatch = useDispatch()

    const { token } = useSelector(state => state.auth)

    const test = useSelector(state => state.test)
    const {loading, infoCount} = test

    useEffect(() => {
        dispatch(getInfoCount(token, history))
    }, [dispatch, token, history])

    if (!token) {
        history.push('/');
    }

    return (
        <Layout>
            {loading ? <div>Loading...</div> : <>
                {infoCount.map(([key, value], index) => <DataBox key={index}>
                    <strong>{InfoCountTitle[key]}</strong>
                    <h2>{value}</h2>
                </DataBox>)}
            </>}

        </Layout>
    )
}

export default withRouter(Dashboard)