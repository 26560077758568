import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import styled from 'styled-components'

import Login from './../pages/Login'
import Dashboard from '../pages/Dashboard'
import SideBar from "../modules/SideBar";
import Users from "../pages/Users";

const Layout = styled.div`
    width: calc(100% - 80px);
    min-height: calc(100vh - 80px);
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    background-color: #f5f6f8;
    padding: 80px 15px 0px 65px;
`

const App = () => {
	return (
		<BrowserRouter>
            <SideBar />
            <Switch>
                <Layout>
                    <Route exact path="/" component={Login} />
                    <Route exact path="/dashboard" component={Dashboard} />
                    <Route exact path="/login" component={Login} />
                    <Route exact path="/users" component={Users} />
                </Layout>
            </Switch>
		</BrowserRouter>
	);
};

export default App;