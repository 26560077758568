export const uploadImage = (data) => async dispatch => {
    try{
        return  fetch(`${process.env.REACT_APP_API_BASE_URL}/images/upload`, {
                body: data,
                method: 'POST',
            })
                .then((res) => {
                return res.json();
                })
                .catch((e) => {
                console.log(e, 'more error');
                });
    }
    catch(e){
        console.log({e}, 'Upload failed');
    }
}