import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' 
import rootReducer from './reducers'

const initialState = {}

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['auth']
  }
const persistedReducer = persistReducer(persistConfig, rootReducer)

const middleware = [thunk]

const reducer = () => {

    let store = createStore(
        persistedReducer, 
        initialState,
        composeWithDevTools(applyMiddleware(...middleware))
    )

    let persistor = persistStore(store)

    return { store, persistor }

}


export const { store, persistor } = reducer()