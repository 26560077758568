// import { PUBLISH_THREAD, PUBLISH_THREAD_FAILED } from './types'
import axios from 'axios'

export const publishThread = (data, token, user_id) => async dispatch => {
    try{

        return await axios.post(`${process.env.REACT_APP_API_ADMIN_URL}/publish/${user_id}`, data, {
            headers: {
            'token': token
            }
        }).then( res => {
            
            console.log({res}, 'Publish Success');

            //   dispatch( {
            //     type: PUBLISH_THREAD,
            //     payload: res.data
            // })
          }).catch(err => {
              console.log(err, 'Publish failed');
          })
    }
    catch(e){
        console.log({e}, 'Publish failed');

        // dispatch( {
        //     type: PUBLISH_THREAD_FAILED,
        //     payload: console.log(e),
        // })
    }

}