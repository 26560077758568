import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { useSelector, useDispatch } from'react-redux'

import { publishThread } from '../redux/actions/publishAction'
import { uploadImage } from '../redux/actions/uploadImage'
import { getUserThreads, getThread } from '../redux/actions/testAction'


const List = styled.div`
    width: ${({ isSelect }) => isSelect ? '100%' : '90%'};
    min-height: 80px;
    display: flex;
    margin-bottom: 1rem;
    border-radius: 10px 0 0 10px;
    background-color: ${({ isSelect }) => isSelect ? '#ffffff' : '#f0f1f1'};
    overflow: hidden;
    cursor: pointer;
    
    & div:first-child {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    & div:last-child {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }
    ${({ isSelect }) => isSelect ? 'box-shadow: rgba(0,0,0,.1) 0px 0px 20px;' : ''};
`
const Image = styled.img`
    width: 60px;
    height: 100%;
    object-fit: cover;
    margin-right: .5rem;
`
const Initials = styled.div`
    width: 60px;
    height: 100%;
    background-color: ${({ isSelect }) => isSelect ? '#2cbfc2' : '#ffffff'};
    color: ${({ isSelect }) => isSelect ? '#ffffff' : '#2cbfc2'};
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: .5rem;
    font-size: 14px;
    font-weight: bold;
`
const ListHeader = styled.div`
    width: 100%;
    height: 50px;
    position: fixed;
    box-shadow: rgba(0,0,0,.1) 0px 0px 20px;
    background-color: #fff;
    top: 50px;
    left: 50px;
    z-index: 0;
    padding: 0 1rem 0 2rem;
    display: flex;
    align-items: center;
    font-weight: bold;
`
const Layout = styled.div`
    min-width: 100%;
    max-height: calc(100vh - 112px);
    display: flex;
    margin-top: 2rem;
    overflow-y: hidden;
    overflow-x: scroll;
`
const ListSection = styled.div`
    min-width: 370px;
    display: flex;
    flex-direction: column;
    overflow: scroll;
    max-height: 100%;
`
const CreationSection = styled.div`
    display: flex;
    justify-content: space-around;
    background-color: #fff;
    max-width: calc(100vw - 350px);
    min-height: 100%;
    overflow-y: scroll;
    overflow-x: scroll;
`

const ThreadForm = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 400px;
    min-width: 400px;
    padding: 0 1rem 1rem;
    border: 1px solid #e1e1e1;
    max-height: 100%;
    overflow: scroll;
`
const InputStyled = styled.input`
    padding: 5px;
    outline: none;
    margin-bottom: 1rem;
`
const TextAreaStyled = styled.textarea`
    padding: 5px;
    min-height: 80px;
    outline: none;
    margin-bottom: 1rem;
`
const TitleForm = styled.h3`
    text-align: center;
`;

const MoreIcon = styled(FontAwesomeIcon)`
    margin: 30px auto 0;
    cursor: pointer;
`
const ThreadPreview = styled.div`
    width: calc(100% - 2.2rem);
    padding: 1rem;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    border: 1px solid #e1e1e1;
    &:first-child {
        margin-top: 1rem;
    }
    ${({ detailSection }) => detailSection && 'cursor: pointer'}
`
const PublishButton = styled.input`
    width: 150px;
    min-height: 50px;
    margin: 1rem auto;
    background-color: ${({ enabled }) => enabled ? '#2cbfc2' : 'gray'};
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: ${({ enabled }) => enabled ? 'pointer' : 'auto'};
    border: none;
    outline: none;
`
const UserThreadsList = styled.div`
    min-width: 400px;
    margin-left: 1rem;
    max-height: 100%;
    overflow: scroll;
`
const CreationScroll = styled.div`
    width: 100%;
    height: 100%;
    overflow-x: scroll;
    display: flex;
`

const TextAreaAndImgBox = ({textareaEl, imgInputRef, uploadImageHandler, imgLoading, currentImg}) =>{
    return (
    <>
        <TextAreaStyled 
            placeholder="Descripción" 
            ref={textareaEl} 
            maxLength={160} />
        <div>
            <label htmlFor="t-image" >Imagen:</label>{' '}
            <input placeholder="Imagen" id="t-image" type="file" name="t-image" ref={imgInputRef} onChange={uploadImageHandler}/>
            {currentImg && <img alt="min preview" width={40} height={40} src={currentImg}/>}
            {imgLoading && <strong>Cargando imagen</strong>}
        </div>
    </>
    )
} 


const UsersList = ({ users = []}) => {
    
    const [userSelect, setUserSelect] = useState({})
    const [threadValues, setThreadValues] = useState({
        thread: {},
        sub_thread: []
    })
    const [currentImg, setCurrentImg] = useState(null)
    const [userThreads, setUserThreads] = useState([])
    const [imgLoading, setImgLoading] = useState(false)
    const [threadDetail, setThreadDetail] = useState({})

    const inputTitle = useRef(null)
    const textareaEl = useRef(null)
    const imgInputRef = useRef(null)

    const dispatch = useDispatch()
    const { token } = useSelector(state => state.auth)

    useEffect(() => {
        users.length > 0 && setUserSelect(users[0])
    }, [users])

    useEffect(() => {
        dispatch(getUserThreads(token, userSelect.user_id)).then(res => setUserThreads(res.data.threads))
    }, [token, userSelect, dispatch])

    const handlePublish = async (event) => {
        dispatch(publishThread(threadValues, token, userSelect.user_id)).then(res => setThreadValues({
            thread: {},
            sub_thread: []
        }))
      };
    
    const uploadImageHandler = (files) => {
        let reader = new FileReader();
        const formData = new FormData();
        if (files.value !== null) {
            const file = files.target.files[0]
            reader.readAsDataURL(file);
            formData.append('file', file);
            setImgLoading(true)
            dispatch(uploadImage(formData)).then(img => {
                imgInputRef.current.value = null
                setCurrentImg(img.result)
                setImgLoading(false)
            })
        }
    }

    const preAddThread = (data) => {
        setThreadValues({...threadValues, ...data})
        setCurrentImg(null)
        textareaEl.current.value = ''
    }

    const getThreadSelected = (threadId) => {
        dispatch(getThread(token, threadId)).then( th => setThreadDetail(th.data))
    }

    return (
        <>
            <ListHeader>Usuario</ListHeader>
            <Layout>
                <ListSection>
                    {users.map(( user, index) => (
                        <List key={index} isSelect={user.user_id === userSelect.user_id} onClick={() => {
                                setUserSelect(user)
                                setThreadDetail({})
                            }}>
                            {user.image ? <Image alt="user-img" src={user.image} /> : <Initials isSelect={user.user_id === userSelect.user_id}>IMG</Initials>}
                            <div>
                                <strong>{user.fullname}</strong>
                                <span>{user.username}</span>
                                <span>ID: {user.user_id}</span>
                            </div>
                        </List>)
                        )
                    }
                </ListSection>
                <CreationSection>
                    <CreationScroll>
                        <ThreadForm>
                            {threadValues.thread.title && (
                                <ThreadPreview>
                                    <img alt="preview" src={threadValues.thread.image}/>
                                    <hr width="300"/>
                                    <strong>{threadValues.thread.title}</strong>
                                    <span>{threadValues.thread.description}</span>
                                </ThreadPreview>
                            )}
                            {threadValues.sub_thread.map((st, index) => (
                                    <ThreadPreview key={index}><img alt="preview" src={st.image}/>{st.description}</ThreadPreview>
                                ))}
                            {!threadValues.thread.title && (<>
                                <TitleForm>Crear Hilo</TitleForm>
                                <InputStyled placeholder="Title" type="text" ref={inputTitle}/>
                            </>)}
                            <TextAreaAndImgBox textareaEl={textareaEl} imgInputRef={imgInputRef} currentImg={currentImg} imgLoading={imgLoading} uploadImageHandler={uploadImageHandler}/>
                            <MoreIcon 
                                icon={faPlus} 
                                color="#2cbfc2"
                                size="lg" 
                                onClick={() => {
                                        if(!textareaEl.current.value) return 
                                        if(!threadValues.thread.title){
                                            preAddThread({ thread: { title: inputTitle.current.value, description: textareaEl.current.value, image: currentImg }})
                                        } else {
                                            preAddThread({ sub_thread: [...threadValues.sub_thread, ...[{description: textareaEl.current.value, image: currentImg}] ] })
                                        }
                                    } 
                                } 
                            />
                            <PublishButton type="submit" onClick={threadValues.thread.title && handlePublish} enabled={threadValues.thread.title} onSubmit={e => e.preventDefault()} value="Publicar Hilo" />
                        </ThreadForm>
                        {userThreads.length > 0 && <UserThreadsList>
                            <strong>Hilos</strong>
                            {userThreads.map((th, index) => (<ThreadPreview detailSection onClick={() => getThreadSelected(th.thread_id)} key={index}>
                                {th.image && <img alt="preview" width="200" src={th.image}/>}
                                <strong>{th.title}</strong>
                                <span>{th.description}</span>
                            </ThreadPreview>))}
                        </UserThreadsList>}
                        {threadDetail.thread && <UserThreadsList>
                            {threadDetail.thread.title && (
                                <ThreadPreview>
                                    <img alt="preview" src={threadDetail.thread.image}/>
                                    <hr width="300"/>
                                    <strong>{threadDetail.thread.title}</strong>
                                    <span>{threadDetail.thread.description}</span>
                                </ThreadPreview>
                            )}
                            {threadDetail.sub_threads && threadDetail.sub_threads.map((st, index) => (
                                <ThreadPreview key={index}><img alt="preview" src={st.image}/>{st.description}</ThreadPreview>
                            ))}
                        </UserThreadsList>}
                    </CreationScroll>
                </CreationSection>
            </Layout>
        </>
    )
}

export default UsersList