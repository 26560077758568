import { combineReducers } from 'redux'

import todoReducer from './todoReducer'
import testReducer from './testReducer'
import authReducer from './authReducer'

export default combineReducers({
    todo: todoReducer,
    test: testReducer,
    auth: authReducer
  })